import React from 'react';
import './Home.css';

class Home extends React.Component {
  render() {
    return (
      <div className="Home">
      </div>
    );
  }
}

export default Home;
